import { authConstants } from "../_constants";

let user;

if (typeof window !== "undefined") {
  user = JSON.parse(localStorage.getItem("user"));
}

const initialState = {
  signUpToken: null,
  signUpRequest: false,
  signUpSuccess: false,
  signUpFailure: false,
  requestOTPRequest: false,
  requestOTPSuccess: false,
  requestOTPFailure: false,
  verifyOTPRequest: false,
  verifyOTPSuccess: false,
  verifyOTPFailure: false,
  passwordSignInRequest: false,
  passwordSignInSuccess: false,
  passwordSignInFailure: false,
  passwordSignInFailureReason: null,
  jT: user ? user.data?.jT : null,
  isLoggedIn: user ? true : false,
  userDetails: user ? user.data?.user : null
};

export function auth(state, action) {
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case authConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        signUpRequest: true
      };
    case authConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        jT: action.data["jT"],
        userDetails: action.data["user"],
        signUpSuccess: true,
        signUpRequest: false,
      };
    case authConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        signUpFailure: true,
        signUpRequest: false
      };
    case authConstants.VERIFY_OTP_REQUEST:
      return {
        ...state,
        verifyOTPRequest: true
      };
    case authConstants.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        jT: action.data["jT"],
        userDetails: action.data["user"],
        verifyOTPSuccess: true,
        verifyOTPRequest: false,
        isLoggedIn: true
      };
    case authConstants.VERIFY_OTP_FAILURE:
      return {
        ...state,
        verifyOTPFailure: true,
        verifyOTPRequest: false
      };
    case authConstants.REQUEST_OTP_REQUEST:
      return {
        ...state,
        requestOTPRequest: true
      };
    case authConstants.REQUEST_OTP_SUCCESS:
      return {
        ...state,
        jT: action.data["jT"],
        userDetails: action.data["user"],
        requestOTPSuccess: true,
        requestOTPRequest: false
      };
    case authConstants.REQUEST_OTP_FAILURE:
      return {
        ...state,
        requestOTPFailure: true,
        requestOTPRequest: false
      };
    case authConstants.PASSWORD_SIGNIN_REQUEST:
      return {
        ...state,
        passwordSignInRequest: true
      };
    case authConstants.PASSWORD_SIGNIN_SUCCESS:
      return {
        ...state,
        jT: action.data["jT"],
        userDetails: action.data["user"],
        passwordSignInSuccess: true,
        passwordSignInRequest: false,
        isLoggedIn: true
      };
    case authConstants.PASSWORD_SIGNIN_FAILURE:
      return {
        ...state,
        passwordSignInFailure: true,
        passwordSignInRequest: false,
        passwordSignInFailureReason: action.error
      };
    case authConstants.REFRESH_REQUEST:
      return {
        ...state
      };
    case authConstants.REFRESH_SUCCESS:
      return {
        ...state,
        jT: action.jT,
        userDetails: action.userDetails
      };
    case authConstants.REFRESH_FAILURE:
      return {
        ...state
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        userDetails: null,
        jT: null,
        isLoggedIn: false
      };
    default:
      return state;
  }
}

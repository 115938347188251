import { routingConstants } from "../_constants";

const initialState = {
  currentPage: "homePage",
  currentStep: 1
};

export function routing(state, action) {
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case routingConstants.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };

    case routingConstants.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.currentStep
      };

    default:
      return state;
  }
}
